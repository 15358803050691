import { IIconProps } from "."

export function CatIcon(props: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3388 2.41943L16.9862 3.82959C16.8795 4.25659 17.0645 4.70317 17.4419 4.92962L18.9823 5.85384C19.2835 6.03456 19.4818 6.36258 19.4231 6.70889C19.3593 7.08476 19.2174 7.45997 18.9355 7.74187C18.4033 8.27412 17.3388 8.27412 17.3388 8.80636C17.3388 9.22293 18.018 12.3904 18.3797 14.0226C18.3955 14.0939 18.4033 14.1656 18.4033 14.2386V18.6529C18.4033 19.0938 18.7607 19.4512 19.2016 19.4512V19.4512C19.6426 19.4512 20 19.8087 20 20.2496V20.8544C20 21.2553 19.6751 21.5802 19.2742 21.5802H5.89553C4.86671 21.5802 4.03268 20.7462 4.03268 19.7174V19.7174C4.03268 18.6885 4.86671 17.8545 5.89553 17.8545V17.8545C6.92436 17.8545 7.75839 17.0205 7.75839 15.9917V15.7255C7.75839 14.5497 6.80521 13.5966 5.62941 13.5966H3.50044"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17.3394 2.41943C16.8965 2.53015 16.1773 2.79286 15.6991 3.1817C15.2706 3.53013 14.7477 4.03728 14.2576 4.29175C13.674 4.59469 13.0814 5.17237 13.0814 6.14514C13.0814 6.57112 13.1603 6.93001 13.2787 7.22578C13.5323 7.85947 13.6188 8.74107 13.1015 9.18639C12.2896 9.88538 11.2934 10.7857 10.9524 11.4676C10.5266 12.3192 9.71052 18.5642 9.3557 21.5802"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.936 19.4513H16.7426C16.1903 19.4513 15.7426 19.0036 15.7426 18.4513V17.8546M15.7426 14.6611V17.8546M15.7426 17.8546C15.5652 17.1449 14.7845 15.7256 13.0814 15.7256"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
