import store from "store"
import get from "lodash/get"
import * as config from "../util/config"

export const getShippingFormLink = (
  setShippingFormLink: (url: string) => void,
) => {
  const lastVisitedPage = store.get("last_visited_page")
  const lastVisitedPageName = get(lastVisitedPage, "name", null)
  const appHost = config.getAppHost()

  switch (lastVisitedPageName) {
    case "dog":
      return setShippingFormLink(`${appHost}/shipments/new/animals/dogs`)
    case "cat":
      return setShippingFormLink(`${appHost}/shipments/new/animals/cats`)
    case "pet":
      return setShippingFormLink(`${appHost}/shipments/new/animals`)
    case "boat":
      return setShippingFormLink(`${appHost}/shipments/new/boats`)
    case "equipment":
      return setShippingFormLink(`${appHost}/shipments/new/heavy-equipment`)
    case "motorcycle":
      return setShippingFormLink(`${appHost}/shipments/new/motorcycles`)
    case "vehicle":
      return setShippingFormLink(`${appHost}/shipments/new/vehicles`)
    case "specialcare":
      return setShippingFormLink(`${appHost}/shipments/new/other`)
    case "shipping":
      return setShippingFormLink(`${appHost}/shipments/new/household-items`)

    default:
      setShippingFormLink(`${appHost}/shipments/new`)
  }
}

export const generateFormQueryParams = (
  options: {
    query?: Record<string, string | number>
  } = {},
) => {
  const queryParams = new URLSearchParams()
  if (options.query) {
    for (const [key, value] of Object.entries(options.query)) {
      if (value != "") {
        queryParams.set(key, String(value))
      }
    }
  }

  const queryParamsAsString = queryParams.toString()

  return queryParamsAsString.length > 0 ? `?${queryParamsAsString}` : ""
}
