import { IIconProps } from "."

export function TransportIcon(props: IIconProps) {
  return (
    <svg
      width="40"
      height="28"
      viewBox="0 0 40 28"
      fill="currentColor"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M31.6369 21.7295C32.405 21.7215 33.1355 22.1135 33.6245 22.7958C34.1139 23.478 34.3097 24.3781 34.1575 25.2454C34.0054 26.1129 33.5217 26.8549 32.841 27.2653C32.1602 27.6754 31.3553 27.7105 30.6497 27.3602C29.9441 27.0098 29.4136 26.3117 29.2052 25.4596C28.9972 24.608 29.1339 23.6937 29.5771 22.971C30.0472 22.1957 30.8157 21.7325 31.637 21.7296L31.6369 21.7295Z" />
      <path d="M14.5148 21.7295C15.2833 21.7215 16.0134 22.1135 16.5027 22.7958C16.9918 23.478 17.1876 24.3781 17.0355 25.2454C16.8834 26.1129 16.3997 26.8549 15.7189 27.2653C15.0385 27.6754 14.2332 27.7105 13.5277 27.3602C12.8221 27.0098 12.2916 26.3117 12.0835 25.4596C11.8751 24.608 12.0118 23.6937 12.455 22.971C12.9252 22.1957 13.6937 21.7325 14.5149 21.7296L14.5148 21.7295Z" />
      <path
        opacity="0.4"
        d="M5.71387 3.14221H0.799539V5.92185H5.71387V3.14221Z"
      />
      <path d="M8.5612 4.10621C8.56371 3.12426 8.90378 2.18326 9.5066 1.48909C10.1097 0.79454 10.9269 0.403267 11.7796 0.400024H27.1152V5.12542H32.0393C32.5808 5.12397 33.1101 5.31073 33.558 5.66107C34.0059 6.01179 34.3513 6.50944 34.5496 7.08999L36.4322 13.0199L38.2023 13.5943C38.4896 13.6819 38.7432 13.877 38.9239 14.1488C39.1043 14.4202 39.2014 14.7532 39.2002 15.0952V22.9709H35.6599C35.263 21.6914 34.4232 20.6585 33.3408 20.1178C32.2583 19.5774 31.0297 19.5774 29.9473 20.1178C28.8649 20.6585 28.0254 21.6914 27.6284 22.9709H18.551C18.154 21.6914 17.3145 20.6585 16.2321 20.1178C15.1497 19.5774 13.9211 19.5774 12.8383 20.1178C11.7559 20.6585 10.9164 21.6914 10.5195 22.9709H8.55793V16.2813H13.1602V13.5017L4.2452 13.5943V10.8147H8.55801L8.5612 4.10621ZM27.1152 13.6497H33.2139L31.6851 8.83161H27.1149L27.1152 13.6497Z" />
    </svg>
  )
}
export default TransportIcon
