import { IIconProps } from "."

export function MessageIcon(props: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.999 20.5H6.99902C3.99902 20.5 1.99902 19 1.99902 15.5V8.5C1.99902 5 3.99902 3.5 6.99902 3.5H16.999C19.999 3.5 21.999 5 21.999 8.5V15.5C21.999 19 19.999 20.5 16.999 20.5Z"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.999 9L13.869 11.5C12.839 12.32 11.149 12.32 10.119 11.5L6.99902 9"
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
