import { IIconProps } from "."

export function QuotesIcon(props: IIconProps) {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="currentColor"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0487 27.2774C21.0487 31.965 17.2137 35.8 12.5261 35.8C7.83846 35.8 4.00342 31.965 4.00342 27.2774C4.00342 17.8598 11.6312 10.2321 21.0487 10.2321C21.0487 10.2321 15.4665 12.3202 12.5687 18.7547C17.2563 18.7547 21.0487 22.5898 21.0487 27.2774Z"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8753 18.7547C38.5629 18.7547 42.3554 22.5898 42.3554 27.2774C42.3554 31.965 38.5203 35.8 33.8327 35.8C29.1451 35.8 25.3101 31.965 25.3101 27.2774C25.3101 17.8598 32.9378 10.2321 42.3554 10.2321C42.3554 10.2321 36.7731 12.32 33.8753 18.7547Z"
      />
    </svg>
  )
}
