import { IIconProps } from "."

export function PowerBoatIcon(props: IIconProps) {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.06667 13.0011L25 9.15723C25 17.3137 17.6022 18.0012 16.1226 18.0012H3.54624L2.06667 13.0011Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.73333 11.9995L3.96648 10.9629C3.77791 10.708 3.47962 10.5576 3.16255 10.5576H2.4361C1.7398 10.5576 1.25665 11.2515 1.4982 11.9045L2.6 14.8833"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.1333 15.605L24.4666 12.7212"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.082 10.0001L16.8627 6L13.2669 6.23234"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.164 6.43848L14.6436 10.0011"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
