import { HTMLAttributes } from "react"

export interface IIconProps extends HTMLAttributes<SVGElement> {}

export * from "./ReceivedIcon"
export * from "./SendIcon"
export * from "./MessageIcon"
export * from "./DriverIcon"
export * from "./FastStopwatchIcon"
export * from "./QuotesIcon"
export * from "./TransportIcon"
export * from "./CalculatorIcon"
export * from "./BoatIcon"
export * from "./BoxIcon"
export * from "./CarIcon"
export * from "./FreightIcon"
export * from "./FurnitureIcon"
export * from "./HomeIcon"
export * from "./MotorcycleIcon"
export * from "./PawIcon"
export * from "./TractorIcon"
export * from "./CatIcon"
export * from "./ChopperIcon"
export * from "./DogIcon"
export * from "./PowerBoatIcon"
export * from "./SUVIcon"

// do not add more exports here - doing so adds to import size
