import { IIconProps } from "."

export function CarIcon(props: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      {...props}
    >
      <path d="M21.596 17.21H2.404a.247.247 0 0 1-.247-.25v-5.436a.25.25 0 0 1 .073-.177l1.046-1.045a.25.25 0 0 0 .058-.091L5.57 4.058a.25.25 0 0 1 .235-.165h12.387c.105 0 .199.066.235.165l2.237 6.151a.25.25 0 0 0 .058.092l1.045 1.045a.25.25 0 0 1 .074.177v5.436c0 .138-.108.25-.246.25z" />
      <path d="M17.037 5.631H6.962a.25.25 0 0 0-.234.163l-1.55 4.131a.25.25 0 0 0 .235.338h13.173a.25.25 0 0 0 .234-.338l-1.55-4.131a.25.25 0 0 0-.234-.163zM3.895 7.947l-.086-.086a1.683 1.683 0 0 0-1.19-.493h-.325a1.158 1.158 0 0 0 0 2.316h1.022M20.105 7.947l.086-.086a1.683 1.683 0 0 1 1.19-.493h.324a1.158 1.158 0 0 1 0 2.316h-1.021" />
      <circle cx="5.631" cy="13.737" r=".987" fill="#D9D9D9" />
      <circle cx="18.368" cy="13.737" r=".987" fill="#D9D9D9" />
      <path d="M14.848 15.474H9.152a.868.868 0 0 1 0-1.737h5.696a.868.868 0 0 1 0 1.737zM2.158 16.631v1.737a1.737 1.737 0 0 0 3.473 0V17.21M21.842 17.21v1.158a1.737 1.737 0 1 1-3.474 0V17.21" />
    </svg>
  )
}
export default CarIcon
