import { IIconProps } from "."

export function FastStopwatchIcon(props: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      fill="currentColor"
      {...props}
    >
      <path d="m35.496 15.401-.978.99a14.188 14.188 0 0 1-11.342 22.703c-1.986.005-3.951-.41-5.766-1.216-1.816-.806-3.022-2.176-4.35-3.652a.88.88 0 0 1 .076-1.228c.355-.32.482-.108.81.239A12.433 12.433 0 0 0 32.67 16.879a.88.88 0 0 1 .05-1.193l1.534-1.535a.623.623 0 0 0-.88-.88l-1.594 1.595a.88.88 0 0 1-1.151.081 12.394 12.394 0 0 0-6.048-2.4.88.88 0 0 1-.785-.874V8.836a.88.88 0 0 1 .88-.894h.806a.623.623 0 1 0 0-1.246h-4.618a.623.623 0 0 0 0 1.246h.806a.88.88 0 0 1 .88.88v2.851a.88.88 0 0 1-.781.873 12.447 12.447 0 0 0-7.49 3.672c-.342.348-.447.353-.795.012a.882.882 0 0 1-.012-1.248c2.097-2.143 4.363-3.568 7.318-4.073V9.702a2.383 2.383 0 0 1 .074-4.752h4.618a2.384 2.384 0 0 1 .074 4.752v1.193a14.164 14.164 0 0 1 5.502 2.186l1.056-1.056a2.383 2.383 0 1 1 3.372 3.369l.011.007ZM23.172 35.374a10.477 10.477 0 0 0 5.258-19.546 10.477 10.477 0 0 0-12.65 1.664.881.881 0 0 0 1.246 1.246 8.72 8.72 0 1 1 0 12.32.88.88 0 1 0-1.246 1.246 10.445 10.445 0 0 0 7.392 3.07Z" />
      <path
        d="M6.632 20.392a.88.88 0 0 0 .88.88h6.11a.88.88 0 1 0 0-1.76H7.516a.88.88 0 0 0-.88.877l-.003.003Zm10.004 4.267a.881.881 0 0 0-.88-.88H9.648a.88.88 0 1 0 0 1.76h6.107a.88.88 0 0 0 .88-.88Zm-9.121 5.15h6.107a.88.88 0 1 0 0-1.76H7.515a.88.88 0 0 0 0 1.76Zm20.54-8.547-2.257 2.256a2.975 2.975 0 1 1-1.246-1.246l2.256-2.256a.881.881 0 0 1 1.246 1.246Zm-3.676 3.64a1.207 1.207 0 1 0-2.415 0 1.207 1.207 0 0 0 2.415 0Z"
        opacity=".6"
      />
      <path d="M23.203 16.311a8.453 8.453 0 0 0-5.733 2.231 2.781 2.781 0 1 1-3.757-4.103c2.5-2.289 5.835-3.69 9.49-3.69 7.793 0 14.087 6.344 14.087 14.142 0 7.798-6.294 14.143-14.087 14.143a14.022 14.022 0 0 1-9.884-4.066 2.781 2.781 0 0 1 3.911-3.955 8.46 8.46 0 0 0 5.973 2.458c4.695 0 8.524-3.828 8.524-8.58 0-4.751-3.829-8.58-8.524-8.58Z" />
      <path d="M20.083 6.6h6.081v1.32l-.942 1.014v2.38h-4.196v-2.38l-.943-1.014V6.6ZM32.198 12.776h2.546v2.546h-2.546z" />
    </svg>
  )
}
export default FastStopwatchIcon
