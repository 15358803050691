import { IIconProps } from "."

export function DogIcon(props: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.4444 3H18.6667V4.6875L20.3333 5.25V5.8125L22 6.375V8.0625L20.8889 9.75H18.6667C18.6667 12.5625 17.5556 13.6875 17 14.25V18.75H17.9525V21H14.4807C14.4807 20.55 13.5676 17.0625 13.1111 15.375C12 15.1875 9.55556 14.8125 8.66667 14.8125C8.22222 16.6125 5.51852 17.8125 4.22222 18.1875H5.33333V21H2V15.9375C3.66667 15.375 3.66667 13.6875 3.66667 13.125C3.66667 12.675 4.03704 11.8125 4.22222 11.4375L3.66667 10.875C3.22222 10.425 3.11111 9.1875 3.11111 8.625H5.88889V9.75C6.44444 9.75 6.44444 9.1875 7.55556 8.99033C8.66667 8.79316 13.6667 8.0625 14.2222 7.5C14.6667 7.05 15.8889 4.3125 16.4444 3Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
