import { IIconProps } from "."

export function DriverIcon(props: IIconProps) {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="currentColor"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.4"
        d="M25.5781 14.063C24.7489 14.0415 23.8914 14.019 23.0001 14.019C22.1089 14.019 21.2511 14.0412 20.4219 14.063C19.6746 14.0822 18.9689 14.1007 18.3066 14.1007C17.2529 14.1007 16.0796 14.058 15.1226 13.7867C14.7719 14.7135 14.5781 15.7178 14.5781 16.7672C14.5781 21.419 18.3489 25.1896 23.0005 25.1896C27.6518 25.1896 31.4229 21.4189 31.4229 16.7672C31.4229 15.7182 31.2297 14.7147 30.8789 13.7882C29.8099 14.0997 28.576 14.1005 27.6942 14.1005C27.0312 14.1005 26.3251 14.0822 25.5779 14.063L25.5781 14.063Z"
      />
      <path d="M29.9006 24.4272C28.0673 26.0697 25.6488 27.0708 22.9976 27.0708C20.3485 27.0708 17.93 26.0697 16.099 24.4272C14.8529 25.0895 13.7056 25.9253 12.6865 26.9041C7.09483 32.2746 12.6226 40.6903 20.3245 41.5784C21.2534 41.6855 22.1558 41.7477 22.9992 41.7477C23.8429 41.7477 24.7457 41.6854 25.675 41.5783C33.3763 40.6899 38.9044 32.275 33.3136 26.9046C32.2941 25.9254 31.1465 25.0894 29.9 24.4272H29.9006Z" />
      <path d="M23.0004 13.2054C28.1452 13.2054 32.3163 13.9441 32.3163 10.9811C32.3163 8.01811 28.145 4.15808 23.0004 4.15808C17.8551 4.15808 13.6846 8.01831 13.6846 10.9811C13.6846 13.9439 17.8558 13.2056 23.0004 13.2056V13.2054Z" />
    </svg>
  )
}
