import { IIconProps } from "."

export function SUVIcon(props: IIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.93242 14.9327H2.5C1.94772 14.9327 1.5 14.485 1.5 13.9327V11.1359C1.5 10.844 1.62756 10.5666 1.84921 10.3767L5.47587 7.26809C5.65711 7.11274 5.88596 7.02734 6.12468 7.02734C7.9263 7.02734 12.8344 7.02734 14.5579 7.02734C14.7663 7.02734 14.9695 7.09247 15.1391 7.21361L18.929 9.92073C19.0642 10.0173 19.2214 10.0787 19.3863 10.0993L23.1239 10.5665C23.6244 10.629 23.9999 11.0544 23.9999 11.5588V13.9327C23.9999 14.485 23.5522 14.9327 22.9999 14.9327H21.5675"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.29113 8.92479L4.96682 10.2491C4.80932 10.4066 4.92087 10.6759 5.14359 10.6759H15.9521C16.1924 10.6759 16.2943 10.37 16.1021 10.2259L14.3364 8.90156C14.2931 8.86911 14.2405 8.85156 14.1864 8.85156H6.46791C6.4016 8.85156 6.33802 8.8779 6.29113 8.92479Z"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.23 8.85156V10.6759M8.18945 15.041H11.23M16.7029 15.041H11.23M11.23 15.041V12.5002"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="5.75664" cy="15.5408" r="1.68242" strokeWidth="1.5" />
      <circle cx="19.1351" cy="15.5408" r="1.68242" strokeWidth="1.5" />
      <path d="M21.5674 12.5H23.9998" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
