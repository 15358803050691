import { IIconProps } from "."

export function MotorcycleIcon(props: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="4.66667" cy="14.9997" r="2.91667" strokeWidth="1.5" />
      <circle cx="19.3337" cy="14.9997" r="2.91667" strokeWidth="1.5" />
      <path
        d="M5.88877 7.05523C5.39988 6.56634 3.24062 6.27901 2.2221 6.19645H1.75245C1.51328 6.19645 2.66226 8.00961 3.31331 8.74802C3.39744 8.84345 3.50368 8.91373 3.62018 8.96485C5.10118 9.61471 7.86362 11.0049 8.33321 11.9441C8.73771 12.7531 9.44899 14.4546 9.91325 15.5947C10.0675 15.9735 10.4345 16.2219 10.8434 16.2219H13.2642C13.8821 16.2219 14.3415 15.662 14.3698 15.0447C14.5024 12.1553 17.0273 10.6526 18.111 10.1108C19.066 9.63328 19.6479 9.90199 20.1482 10.0423C20.4036 10.1139 20.6665 10.0089 20.8187 9.79176C20.9949 9.54037 21.1665 9.20588 21.1665 8.88856C21.1665 7.05523 18.7221 5.83301 18.111 5.83301C17.6494 5.83301 16.8391 5.83301 16.4703 6.09639C16.2544 6.25051 16.383 6.54948 16.5706 6.73701L16.5959 6.76234C16.7834 6.94987 16.8888 7.20403 16.8888 7.46924V8.4745C16.8888 8.73971 16.782 8.99709 16.5469 9.11988C15.8112 9.50415 14.3272 9.87826 13.4288 10.0682C13.2926 10.097 13.1526 10.0954 13.0168 10.0649C11.3892 9.69904 8.47404 8.9977 7.07149 8.50723C6.71395 8.3822 6.50105 8.02743 6.33836 7.68537C6.24433 7.48768 6.10115 7.26761 5.88877 7.05523Z"
        strokeWidth="1.5"
      />
      <path
        d="M10.167 9.49935C10.167 8.88824 12.0003 7.66602 13.2225 7.66602C14.4448 7.66602 15.667 8.27713 16.2781 8.88824"
        strokeWidth="1.5"
      />
      <path
        d="M18.1114 10.1104C18.1114 10.5992 18.9263 13.5733 19.3337 14.9992M9.55588 14.9992C8.08921 14.9992 5.68551 14.9992 4.66699 14.9992"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
