import { IIconProps } from "."

export function FreightIcon(props: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="3"
        y="6.85742"
        width="18"
        height="10.2857"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M5.57129 9.42871V14.5716"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.14258 9.42871V14.5716"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.7139 9.42871V14.5716"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.2861 9.42871V14.5716"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.8574 9.42871V14.5716"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.4287 9.42871V14.5716"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
