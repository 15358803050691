import { IIconProps } from "."

export function CalculatorIcon(props: IIconProps) {
  return (
    <svg
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="currentColor"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.16667 0C1.86849 0 0 1.79375 0 4V28C0 30.2062 1.86849 32 4.16667 32H20.8333C23.1315 32 25 30.2062 25 28V4C25 1.79375 23.1315 0 20.8333 0H4.16667ZM6.25 4H18.75C19.9023 4 20.8333 4.89375 20.8333 6V8C20.8333 9.10625 19.9023 10 18.75 10H6.25C5.09766 10 4.16667 9.10625 4.16667 8V6C4.16667 4.89375 5.09766 4 6.25 4ZM4.16667 14C4.16667 12.8938 5.09766 12 6.25 12C7.40234 12 8.33333 12.8938 8.33333 14C8.33333 15.1062 7.40234 16 6.25 16C5.09766 16 4.16667 15.1062 4.16667 14ZM6.25 18C7.40234 18 8.33333 18.8937 8.33333 20C8.33333 21.1063 7.40234 22 6.25 22C5.09766 22 4.16667 21.1063 4.16667 20C4.16667 18.8937 5.09766 18 6.25 18ZM4.16667 26C4.16667 24.8937 5.09766 24 6.25 24H12.5C13.6523 24 14.5833 24.8937 14.5833 26C14.5833 27.1063 13.6523 28 12.5 28H6.25C5.09766 28 4.16667 27.1063 4.16667 26ZM12.5 12C13.6523 12 14.5833 12.8938 14.5833 14C14.5833 15.1062 13.6523 16 12.5 16C11.3477 16 10.4167 15.1062 10.4167 14C10.4167 12.8938 11.3477 12 12.5 12ZM10.4167 20C10.4167 18.8937 11.3477 18 12.5 18C13.6523 18 14.5833 18.8937 14.5833 20C14.5833 21.1063 13.6523 22 12.5 22C11.3477 22 10.4167 21.1063 10.4167 20ZM18.75 12C19.9023 12 20.8333 12.8938 20.8333 14C20.8333 15.1062 19.9023 16 18.75 16C17.5977 16 16.6667 15.1062 16.6667 14C16.6667 12.8938 17.5977 12 18.75 12ZM16.6667 20C16.6667 18.8937 17.5977 18 18.75 18C19.9023 18 20.8333 18.8937 20.8333 20C20.8333 21.1063 19.9023 22 18.75 22C17.5977 22 16.6667 21.1063 16.6667 20ZM18.75 24C19.9023 24 20.8333 24.8937 20.8333 26C20.8333 27.1063 19.9023 28 18.75 28C17.5977 28 16.6667 27.1063 16.6667 26C16.6667 24.8937 17.5977 24 18.75 24Z" />
    </svg>
  )
}
