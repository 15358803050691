import { IIconProps } from "."

export function FurnitureIcon(props: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4284 9.2855C18.4284 5.7351 15.5503 2.85693 11.9999 2.85693C8.44946 2.85693 5.57129 5.7351 5.57129 9.2855"
        strokeWidth="1.5"
      />
      <path
        d="M5.4 9.28564C4.07452 9.28564 3 10.3382 3 11.6367C3 12.6975 3.71729 13.5943 4.7027 13.8869V16.6326C4.7027 17.8081 5.7 19.5714 7.8 19.5714H16.2C18.3 19.5714 19.2973 17.8081 19.2973 16.6326V13.8869C20.2827 13.5943 21 12.6975 21 11.6367C21 10.3382 19.9255 9.28564 18.6 9.28564C17.2745 9.28564 16.2 10.3382 16.2 11.6367V15.1632H7.8V11.6367C7.8 10.3382 6.72548 9.28564 5.4 9.28564Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.14307 19.5713V22.1427"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.8569 19.5713V22.1427"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
