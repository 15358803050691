import { IIconProps } from "."

export function BoxIcon(props: IIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="5"
        y="5.875"
        width="14"
        height="3.5"
        rx="0.75"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x="5.875"
        y="9.375"
        width="12.25"
        height="8.75"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x="9.375"
        y="12"
        width="5.25"
        height="1.75"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
