import { IIconProps } from "."

export function BoatIcon(props: IIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.02222 14.9594L24 11.6372C24 17.6172 18.3778 19.1606 15.4925 19.1606H3.44014L2.02222 14.9594Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.08908 3L10.2002 13.6312"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2442 7.65088L14.7998 12.9665"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.06689 8.98016C4.47578 8.98016 11.0521 7.65126 13.778 6.98682"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.57778 14.2961L3.83437 12.8464C3.6632 12.5126 3.31965 12.3027 2.94455 12.3027H2.45603C1.75425 12.3027 1.27076 13.0067 1.52268 13.6617L2.53333 16.2894"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
