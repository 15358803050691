import { IIconProps } from "."

export function ChopperIcon(props: IIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.09082 5.21952C9.18172 4.63395 11.909 5.21997 13.5453 6.97644L21.7271 15.1741"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.1815 9.31738L11.1717 14.1003C10.6017 14.7802 9.75995 15.173 8.87271 15.173H4.27246"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 12.2459C2.0909 11.0748 2.63636 10.4892 4.27271 10.4892C5.90907 10.4892 6.99997 11.6603 8.09088 11.6603C9.18178 11.6603 9.18178 10.4892 10.8181 10.4892C12.4545 10.4892 14.6363 9.90365 14.6363 8.14697"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.27246 10.4904C5.36337 10.4904 5.36337 10.4904 6.45427 10.4904C7.54517 10.4904 10.2724 5.80594 14.0906 7.56261"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M24.2495 15.174C24.2495 16.7512 23.0708 17.9374 21.7268 17.9374C20.3828 17.9374 19.2041 16.7512 19.2041 15.174C19.2041 13.5968 20.3828 12.4106 21.7268 12.4106C23.0708 12.4106 24.2495 13.5968 24.2495 15.174Z"
        strokeWidth="1.5"
      />
      <path
        d="M6.79542 15.174C6.79542 16.7512 5.61674 17.9374 4.27271 17.9374C2.92869 17.9374 1.75 16.7512 1.75 15.174C1.75 13.5968 2.92869 12.4106 4.27271 12.4106C5.61674 12.4106 6.79542 13.5968 6.79542 15.174Z"
        strokeWidth="1.5"
      />
    </svg>
  )
}
